<template>
  <div
    class="z-10 top-0 sticky body__header border-t border-b ticket_table_heading"
  >
    <div class="sm:items-center top-0 flex flex-wrap justify-between">
      <h3 class="text-xl leading-6 font-medium body__header--text">
        SFTP Account
      </h3>
      <div class="inline-flex">
        <div class="flex-1 ml-2 mt-3 xs:mt-0 lg:mt-0">
          <SearchFilter
            v-model:search="search"
            @input="$emit('update:search', this.search)"
            :inputPlaceholder="$t('header.searchSftpAccountPlaceholder')"
          />
        </div>
      </div>
    </div>
  </div>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="flex flex-col mt-3" v-if="this.openUpdateForm">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="pt-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div class="rounded-md overflow-hidden border">
          <UpdateSftpAccount
            v-on:closeForm="this.openUpdateForm = false"
            title="Update SFTP Account"
            :id="this.sftpAccountId"
            :name="this.sftpAccountHostname"
            :username="this.sftpAccountUsername"
            @activeBanner="
              (activeBanner = true), getSftpAccounts(), (key = !key)
            "
            @successMsg="successMsg = $event"
            @success="isSuccess = $event"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div
          v-if="this.filterSftpAccounts().length > 0"
          class="shadow overflow-hidden border-b border-gray-200 rounded-t-md bg-white"
        >
          <TablePagination
            rows="20"
            :tableData="filterSftpAccounts()"
            @sort="sort($event)"
            @openEditForm="(...args) => this.openEditForm(...args)"
            :nextSftpAccountsAvailable="this.nextSftpAccountsAvailable"
          />
        </div>
        <div v-else class="text-center mt-12">
          <svg
            class="mx-auto h-16 w-16 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vector-effect="non-scaling-stroke"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"
            />
          </svg>
          <h3 class="mt-2 text-xl font-medium body__header--text">
            No SFTP account
          </h3>
          <p class="mt-1 text-base text-gray-500">
            Get started by creating a new project.
          </p>
        </div>
      </div>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";
import TablePagination from "../../components/TablePagination.vue";
import UpdateSftpAccount from "../../components/sftp/UpdateSftpAccount.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";

export default {
  title() {
    return `SFTP - ${localStorage.getItem("title")}`;
  },
  name: "SftpAccounts",
  components: {
    Loading,
    TablePagination,
    UpdateSftpAccount,
    SearchFilter,
    SuccessBanner,
  },
  data() {
    return {
      currentSort: "id",
      currentSortDir: "asc",
      fullPage: false,
      isLoading: true,
      openUpdateForm: false,
      nextSftpAccountsAvailable: {},
      search: "",
      sftpAccountId: "",
      sftpAccountHostname: "",
      sftpAccountUsername: "",
      sftpAccounts: [],
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
    };
  },
  methods: {
    openEditForm(...args) {
      this.openForm = false;
      this.openUpdateForm = true;
      this.sftpAccountId = args[0];
      this.sftpAccountHostname = args[1];
      this.sftpAccountUsername = args[2];
      window.scrollTo(0, 100);
    },
    async getSftpAccounts() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/sftpAccounts`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.sftpAccounts = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getNextSftpAccountsAvailable() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/sftpAccounts/available`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        this.nextSftpAccountsAvailable = res.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedSftpAccounts() {
      return this.sftpAccounts.sort((a, b) => {
        let toBeSorted = this.currentSort;
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (toBeSorted === "active") {
          if (parseFloat(a[toBeSorted]) < parseFloat(b[toBeSorted]))
            return -1 * modifier;
          if (parseFloat(a[toBeSorted]) > parseFloat(b[toBeSorted]))
            return 1 * modifier;
        } else if (typeof a[toBeSorted] === "string") {
          if (a[toBeSorted].toLowerCase() < b[toBeSorted].toLowerCase())
            return -1 * modifier;
          if (a[toBeSorted].toLowerCase() > b[toBeSorted].toLowerCase())
            return 1 * modifier;
        }
        return 0;
      });
    },
    filterSftpAccounts() {
      return this.sortedSftpAccounts().filter((list) => {
        return (
          String(list.host)
            .toLowerCase()
            .includes(String(this.search).toLowerCase()) ||
          String(list.username).includes(String(this.search))
        );
      });
    },
  },
  mounted() {
    this.getSftpAccounts();
    this.getNextSftpAccountsAvailable();
  },
};
</script>

<style></style>

<template>
  <div class="z-10">
    <div class="w-full">
      <form
        @submit.prevent="updateSftpAccount(this.id, 'Stfp added successfully')"
        class="shadow space-y-8 divide-y divide-gray-200 form_padding z-10"
        method="POST"
      >
        <div class="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              {{ this.title }}
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Update by complete informations below.
            </p>

            <dl>
              <div class="bg-gray-50 pt-5 sm:grid sm:grid-cols-3">
                <dt class="text-sm font-medium text-gray-500 flex">
                  <ServerIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  {{ $t("sftpAccountTable.host") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ this.name }}
                </dd>
              </div>
              <div class="bg-white py-2 sm:grid sm:grid-cols-3">
                <dt class="text-sm font-medium text-gray-500 flex">
                  <UserIcon
                    class="flex-shrink-0 mr-1.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  {{ $t("sftpAccountTable.username") }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {{ this.username }}
                </dd>
              </div>
            </dl>
          </div>
          <div class="space-y-6 sm:space-y-5">
            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="fileable_id"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Customer
              </label>
              <QuickSearch
                @action="setFileable($event)"
                :placeholder="'Search'"
              />
            </div>
            <!--    <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="fileable_type"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Activate Account
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <Switch
                  v-model="enabled"
                  :class="[
                    enabled ? 'bg-green-500' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 ',
                  ]"
                >
                  <span class="sr-only">Use setting</span>
                  <span
                    :class="[
                      enabled ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    ]"
                  >
                    <span
                      :class="[
                        enabled
                          ? 'opacity-0 ease-out duration-100'
                          : 'opacity-100 ease-in duration-200',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      ]"
                      aria-hidden="true"
                    >
                      <svg
                        class="h-3 w-3 text-gray-400"
                        fill="none"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      :class="[
                        enabled
                          ? 'opacity-100 ease-in duration-200'
                          : 'opacity-0 ease-out duration-100',
                        'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity',
                      ]"
                      aria-hidden="true"
                    >
                      <svg
                        class="h-3 w-3 text-green-600"
                        fill="currentColor"
                        viewBox="0 0 12 12"
                      >
                        <path
                          d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                        />
                      </svg>
                    </span>
                  </span>
                </Switch>
              </div>
            </div> -->

            <div
              class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5"
            >
              <label
                for="comment"
                class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Comment
              </label>
              <div class="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  v-model="this.form.comment"
                  type="text"
                  name="comment"
                  id="comment"
                  class="max-w-lg block w-full shadow-sm focus:ring-malachite-500 focus:border-malachite-light sm:max-w-xs sm:text-sm border-gray-300 rounded"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="pt-5 grid grid-cols-12">
          <div class="rounded-md bg-yellow-50 col-span-7">
            <div class="flex py-2">
              <div class="ml-3 flex-shrink-0">
                <ExclamationIcon
                  class="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 text-sm text-yellow-700">
                <p>
                  <span class="font-medium text-yellow-800"
                    >Attention needed.</span
                  >
                  By updating this SFTP account, you will activate it and you
                  will not be able to edit it.
                </p>
              </div>
            </div>
          </div>
          <div class="flex justify-end col-start-12">
            <a
              href="javascript:void(0)"
              v-on:click="$emit('closeForm')"
              class="rounded bg-white py-2 px-4 border border-gray-300  shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              {{ $t("ticket.cancel") }}
            </a>
            <button
              type="submit"
              class="rounded ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium body__button text-white"
            >
              {{ $t("ticket.send") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ConfirmationDialog
    v-if="this.showMessage"
    :messageToShow="this.messageToShow"
    :subMessageToShow="this.subMessageToShow"
  />
</template>

<script>
import axios from "axios";
import { ref } from "vue";
import { Switch } from "@headlessui/vue";
import {
  SearchIcon,
  ExclamationIcon,
  ServerIcon,
  UserIcon,
} from "@heroicons/vue/solid";
import ConfirmationDialog from "../../components/ConfirmationDialog.vue";

import QuickSearch from "../../components/QuickSearch.vue";

export default {
  name: "UpdateSftpAccount",
  props: ["id", "title", "name", "username"],
  components: {
    Switch,
    ServerIcon,
    UserIcon,
    SearchIcon,
    ExclamationIcon,
    ConfirmationDialog,
    QuickSearch,
  },
  setup() {
    const enabled = ref(false);
    return {
      enabled,
    };
  },
  data() {
    return {
      fileable_types: [
        { id: 0, name: "Customer" },
        { id: 1, name: "Prospect" },
      ],
      form: {
        fileable_type: "",
        fileable_id: "",
        comment: "",
      },
      search: "",
      quickData: [],
      showSearch: true,
      showMessage: false,
      messageToShow: "",
      subMessageToShow: "",
    };
  },
  methods: {
    setFileable(event) {
      this.form.fileable_type = event.searchable_type;
      this.form.fileable_id = event.searchable_id;
    },
    updateSftpAccount(id, msg) {
      const options = {
        method: "PUT",
        url: `${this.$cookie.getCookie("API")}/api/v1/sftpAccounts/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          fileable_type: this.form.fileable_type,
          fileable_id: this.form.fileable_id,
          comment: this.form.comment,
        },
      };
      axios
        .request(options)
        .then((res) => {
          if (res) {
            this.$emit("activeBanner");
            this.$emit("success", true);
            this.$emit("successMsg", msg);
          }
        })
        .catch((error) => {
          this.$emit("activeBanner");
          this.$emit("success", false);
          this.$emit("successMsg", "Something wrong happened.");
          this.errorHandling(error);
        });
    },
    chooseCustomer() {
      let splitValue = this.search.split(",");
      let type = "";
      console.log(splitValue);
      if (splitValue.length > 1) {
        type = splitValue[0];
        this.form.fileable_id = splitValue[1];
        this.search = splitValue[2];
      }
      if (type === "Customer") {
        this.form.fileable_type = "App\\Customer";
      } else {
        this.form.fileable_type = "App\\Prospect";
      }
      console.log(this.form);
      this.showSearch = false;
    },
    sendMessage(msg, subMsg) {
      this.showMessage = true;
      this.messageToShow = msg;
      this.subMessageToShow = subMsg;
    },
  },
};
</script>

<style></style>
